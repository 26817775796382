import { RobotModel } from '@/interfaces'

//NOTE: Regular horst 900
export const HORST_1000: RobotModel = {
  id: '28b2aca0-1e20-4562-a152-8bfca9dcc5bb',
  name: 'HORST 1000',
  systemDisplayConfiguration: {
    image: new URL(`/src/assets/systems/1000.png`, import.meta.url).href,
  },
  maintenanceDisplayConfiguration: {
    image: new URL(`/src/assets/systems/1000.png`, import.meta.url).href, // TODO: Add rendering with bubbles ...
  },
  axisMaxRotations: [9_036_000, 5_361_000, 7_464_000, 6_666_000, 6_986_000, 24_554_000], // TODO: Used values form 900. Might be adjusted

  selectable: true,
}
