import { RobotModel } from '@/interfaces'

// NOTE - Robot Model H1500 based on H1400. H1500 it's a prototype
export const HORST_1500: RobotModel = {
  id: 'a675897c-fc7d-4e9b-881b-66306063b6c3',
  name: 'HORST 1500',
  systemDisplayConfiguration: {
    image: new URL(`/src/assets/systems/1400.png`, import.meta.url).href,
  },
  maintenanceDisplayConfiguration: {
    image: new URL(`/src/assets/robots/1400.png`, import.meta.url).href,
  },
  axisMaxRotations: [3_741_000, 4_669_000, 5_291_000, 9_969_000, 7_778_000, 10_906_000], // TODO: Used values form 1400. Might be adjusted

  selectable: true,
}
